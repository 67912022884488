<!-- 根组件 -->
<template>
    <div class="route-host">
        <MyHeader class="my-header"></MyHeader>
        <div class="content">
            <router-view/>
        </div>
        <MyFooter></MyFooter>
    </div>
</template>
<script>
import MyHeader from "./MyHeader.vue"
import MyFooter from "./MyFooter.vue"
export default {
    components: {
        MyHeader,
        MyFooter,
    },
}
</script>
<style lang="less" scoped>
.route-host {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .my-header {
        z-index: 1;
        position: fixed;
        top:0px;
        left:0px;
        right: 0px;
    }
    .content {
        width: 100%;
        flex:1;
    }
}
</style>