<template>
    <div class="route-host-mobile">
        <MHeader></MHeader>
        <div class="content">
            <router-view/>
        </div>
        <MFooter></MFooter>
    </div>
</template>
<script>
import MHeader from './MHeader.vue';
import MFooter from './MFooter.vue';
export default {
    components: {
        MHeader,
        MFooter,
    },
}
</script>
<style lang="less" scoped>
.route-host-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:#F4F4F4;
    .content {
        width: 100%;
    }

}
</style>