import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//产品中心
const productCenter = [
  //药物临床试验监管系统
  {
    meta: {
      title: '产品中心-药物临床试验监管系统（GCP）'
    },
    path: '/product/gcp',
    name: 'productGCP',
    component: () => import('@/views/product/gcp/gcp.vue')
  },
  //临床试验管理系统
  {
    meta: {
      title: '产品中心-临床试验管理系统(CTIMS)'
    },
    path: '/product/ctims',
    name: 'productCTIMS',
    component: () => import('@/views/product/ctims/ctims.vue')
  },
  //医学伦理委员会管理系统
  {
    meta: {
      title: '产品中心-医学伦理委员会管理系统(ECMS)'
    },
    path: '/product/ecms',
    name: 'productECMS',
    component: () => import('@/views/product/ecms/ecms.vue')
  },
  //远程监查系统
  {
    meta: {
      title: '产品中心-远程监查系统(ABSRMS)'
    },
    path: '/product/absrms',
    name: 'productABSRMS',
    component: () => import('@/views/product/absrms/absrms.vue')
  },
  //临床医生工作站系统
  {
    meta: {
      title: '产品中心-临床医生工作站系统'
    },
    path: '/product/opms',
    name: 'productOPMS',
    component: () => import('@/views/product/opms/opms.vue')
  },
  //I期病房系统（EDC）
  {
    meta: {
      title: '产品中心-I期病房系统'
    },
    path: '/product/edc',
    name: 'productEDC',
    component: () => import('@/views/product/edc/edc.vue')
  },
  //临床科研项目数据管理系统（IIT）
  {
    meta: {
      title: '产品中心-临床科研项目数据管理系统'
    },
    path: '/product/iit',
    name: 'productIIT',
    component: () => import('@/views/product/iit/iit.vue')
  },
]

const routes = [
  //产品中心
  ...productCenter,
  //首页
  {
    meta: {
      title: '首页'
    },
    path: '/',
    name: 'index',
    component: () => import('@/views/home/home.vue')
  },
  //解决方案
  {
    meta: {
      title: '解决方案'
    },
    path: '/solution',
    name: 'solution',
    component: () => import('@/views/solution/solution.vue')
  },
  //新闻动态
  {
    meta: {
      title: '新闻动态'
    },
    path: '/newsroom',
    name: 'newsroom',
    component: () => import('@/views/newsroom/newsroom.vue')
  },
  //新闻详情
  {
    meta: {
      title: '新闻详情'
    },
    path: '/newsroom/news',
    name: 'newscontent',
    component: () => import('@/views/newsroom/newscontent/newscontent.vue')
  },
  //关于我们
  {
    meta: {
      title: '关于我们'
    },
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/about.vue')
  },
  //联系我们
  {
    meta: {
      title: '联系我们'
    },
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/contact/contact.vue')
  },
  //招贤纳士
  {
    meta: {
      title: '招贤纳士'
    },
    path: '/employ',
    name: 'Employ',
    component: () => import('@/views/employ/employ.vue')
  },
  //合作伙伴
  {
    meta: {
      title: '合作伙伴'
    },
    path: '/friends',
    name: 'Friends',
    component: () => import('@/views/friends/friends.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(from.path === '/newsroom/news') {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

export default router
