<template>
    <device-aware>
        <pc slot="pc"></pc>
        <mobile slot="mobile"></mobile>
    </device-aware>
</template>
<script>
import pc from "./pc/RouteHost.vue"
import mobile from './mobile/RouteHost.vue'
export default {
    components: {
        pc,
        mobile,
    }
}
</script>