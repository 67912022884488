import Vue from 'vue'
import App from './App.vue'


Vue.config.productionTip = false

//element start
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)
//element end

//router start
import router from './router'
import'./router/guard.js'
//router end

//store start
import store from './store'
//store end

//components start
import components from "@/components/register.js"
Vue.use(components)
//components end

//自定义样式
import "./css/flexible.js"
//自定义样式 end

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
