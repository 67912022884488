

function install(Vue) {
    //斑马间隔
    Vue.component('zebra', ()=>import('./Zebra/Zebra.vue'))
    //块级信息组件
    Vue.component('block-body', ()=>import('./Block/BlockBody.vue'))
    Vue.component('block-title', ()=>import('./Block/BlockTitle.vue'))
    Vue.component('block-subtitle', ()=>import('./Block/BlockSubTitle.vue'))
    //切换pc/移动端布局
    Vue.component('device-aware', ()=>import('./DeviceAware/DeviceAware.vue'))
    //移动端块级信息组件
    Vue.component('m-block-body', ()=>import('./MBlock/MBlockBody.vue'))
    Vue.component('m-block-title', ()=>import('./MBlock/MBlockTitle.vue'))
    //右侧悬浮按钮
    Vue.component('m-float-buttons', ()=>import('./Page/MFloatButtons.vue'))
    //分割线
    Vue.component('logo-divider', ()=>import('./Divider/LogoDivider.vue'))
    Vue.component('gray-divider', ()=>import('./Divider/GrayDivider.vue'))
    
}
export default {
  install
}
