<!-- 移动端顶部导航 -->
<template>
    <div class="home-page-m-header">
        <!-- title -->
        <div class="title">
            <img src="@/assets/image/mobile/title.jpg" class="bg">
            <div class="content">
                <img src="@/assets/image/logo_text_red.png" class="logo">
            </div>
        </div>
        <!-- banner -->
        <img src="@/assets/image/mobile/banner.jpg" class="banner">
        <!-- 一排入口 -->
        <div class="menu">
            <div class="menu-item" @click="navigate('/')"
                :class="{ active: currentPath === '/' || currentPath.startsWith('/product') }">
                <img src="@/assets/image/mobile/icon_header_product.jpg">
                <span>核心产品</span>
            </div>
            <div class="menu-item" @click="navigate('/about')" :class="{ active: currentPath === '/about' }">
                <img src="@/assets/image/mobile/icon_header_about.jpg">
                <span>关于我们</span>
            </div>
            <div class="menu-item" @click="navigate('/contact')" :class="{ active: currentPath === '/contact' }">
                <img src="@/assets/image/mobile/icon_header_contact.jpg">
                <span>联系我们</span>
            </div>
            <div class="menu-item" @click="navigate('/employ')" :class="{ active: currentPath === '/employ' }">
                <img src="@/assets/image/mobile/icon_header_employ.jpg">
                <span>招贤纳士</span>
            </div>
            <div class="menu-item" @click="navigate('/friends')" :class="{ active: currentPath === '/friends' }">
                <img src="@/assets/image/mobile/icon_header_friends.jpg">
                <span>合作伙伴</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            submenu: {
                visible: false,
                hideTask: null,
            }
        }
    },
    computed: {
        currentPath() {
            return this.$route.path
        },
    },
    methods: {
        navigate(path) {
            this.hideSubmenu(true)
            if (this.$route.path !== path) {
                this.$router.replace({
                    path,
                })
            }
        },
        routeProduct() { },
        showSubmenu() {
            if (this.submenu.hideTask) clearTimeout(this.submenu.hideTask)
            this.submenu.visible = true
        },
        hideSubmenu(nodelay) {
            if (this.submenu.hideTask) clearTimeout(this.submenu.hideTask)
            if (nodelay === true) {
                this.submenu.visible = false
            } else {
                this.submenu.hideTask = setTimeout(() => {
                    this.submenu.visible = false
                }, 200);
            }
        },
    },
}
</script>
<style lang="less" scoped>
.home-page-m-header {
    background-color: #FFF;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 100%;

    display: flex;
    flex-direction: column;

    .title {
        width: 100%;
        height: 122px;
        position: relative;
        overflow: hidden;

        .bg {
            position: absolute;
            bottom: -25px;
            left:0px;
            right:0px;
        }

        .content {
            z-index: 1;
            position: absolute;
            top:0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo {
                height: 37px;
                margin-left: 36px;
            }
        }
    }

    .banner {
        width: 100%;
        height: 422px;
    }


    .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 184px;

        .menu-item {
            font-weight: 400;
            font-size: 22px;
            color: #000000A6;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-top: 32px;
                width: 80px;
                height: 80px;
            }

            span {
                margin-top: 10px;
            }

        }


        .active {
            font-weight: 600;
            font-size: 22px;
            color: #6CA7FF;
        }

    }
}
</style>