<!-- 移动端顶部导航 -->
<template>
    <div class="home-page-m-footer">
        <m-block-title>联系方式</m-block-title>
        <div class="body">
            <span>
                <span class="label">客服电话：</span>
                <span class="tel">022-59955991</span>
                <span class="tel tel2">022-59955991</span>
            </span>
            <span>
                <span class="label">地址：</span>
                <span class="address">天津市南开区科研西路天津科技广场6号楼 1606(天开园)</span>
            </span>
        </div>
        <img src="@/assets/image/mobile/map.jpg" class="map">
    </div>
</template>
<script>
export default {
    computed: {

    },
    methods: {

    },
}
</script>
<style lang="less" scoped>
.home-page-m-footer {
    background-color: #FFF;
    width: 100%;



    .body {
        display: flex;
        flex-direction: column;
        margin-left: 48px;
        margin-top: 40px;
        margin-right: 48px;
        gap: 32px;
        line-height: 42px;

        .label {
            font-weight: 600;
            font-size: 28px;
            color: #6D737A;
        }

        .tel {
            font-weight: 400;
            font-size: 28px;
            color: #2F5AFE;
            text-decoration: underline;
        }

        .tel2 {
            margin-left: 20px;
        }

        .address {
            font-weight: 400;
            font-size: 28px;
            color: #6D737A;
        }
    }

    .map {
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
</style>