<!-- 顶部导航 -->
<template>
    <div class="home-page-header">
        <div class="menu1">
            <img class="logo" src="@/assets/image/logo_text_red.png" @click="navigate('/')">
            <div class="menu-item" @click="navigate('/')">首页</div>
            <div class="menu-item" :class="{ active: currentPath.startsWith('/product') }" @mouseenter="showSubmenu"
                @mouseleave="hideSubmenu" @click="navigate('/product/gcp')">
                产品中心
            </div>
            <div class="menu-item" :class="{ active: currentPath.startsWith('/newsroom') }"
                @click="navigate('/newsroom')">新闻动态
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/solution' }" @click="navigate('/solution')">解决方案
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/about' }" @click="navigate('/about')">关于我们</div>
            <div class="menu-item" :class="{ active: currentPath === '/contact' }" @click="navigate('/contact')">联系我们
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/employ' }" @click="navigate('/employ')">招贤纳士
            </div>
        </div>
        <div class="menu2" :style="{
            display: submenu.visible ? 'flex' : 'none',
        }" @mouseenter="showSubmenu" @mouseleave="hideSubmenu">
            <div class="menu-item" :class="{ active: currentPath === '/product/gcp' }"
                @click="navigate('/product/gcp')">
                <span>药物临床试验</span>
                <span>监管系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/ctims' }"
                @click="navigate('/product/ctims')">
                <span> 临床试验管理</span>
                <span>系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/ecms' }"
                @click="navigate('/product/ecms')">
                <span>医学伦理委员会</span>
                <span>管理系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/absrms' }"
                @click="navigate('/product/absrms')">
                <span>远程监查系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/opms' }"
                @click="navigate('/product/opms')">
                <span>临床医生工作站</span>
                <span>系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/edc' }"
                @click="navigate('/product/edc')">
                <span>I期病房系统</span>
            </div>
            <div class="menu-item" :class="{ active: currentPath === '/product/iit' }"
                @click="navigate('/product/iit')">
                <span>临床科研项目数据</span>
                <span>管理系统</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            submenu: {
                visible: false,
                hideTask: null,
            }
        }
    },
    computed: {
        currentPath() {
            return this.$route.path
        },
    },
    methods: {
        navigate(path) {
            this.hideSubmenu(true)
            if (this.$route.path !== path) {
                this.$router.replace({
                    path,
                })
            }
        },
        routeProduct() { },
        showSubmenu() {
            if (this.submenu.hideTask) clearTimeout(this.submenu.hideTask)
            this.submenu.visible = true
        },
        hideSubmenu(nodelay) {
            if (this.submenu.hideTask) clearTimeout(this.submenu.hideTask)
            if (nodelay === true) {
                this.submenu.visible = false
            } else {
                this.submenu.hideTask = setTimeout(() => {
                    this.submenu.visible = false
                }, 200);
            }
        },
    },
}
</script>
<style lang="less" scoped>
@headerHeight: 80px;
@backgroundColor: #00000066;

.home-page-header {
    color: #FFFFFFFF;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: @backgroundColor;

    .menu1 {
        height: @headerHeight;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
            width: 162px;
            height: 24px;
            margin-left: 40px;
            cursor: pointer;
            margin-right: 38px;
        }

        .menu-item {
            width: 160px;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            cursor: pointer;
            height: @headerHeight;
            line-height: @headerHeight;
            text-align: center;
        }

    }

    .menu2 {
        background-color: #00000033;
        width: 100%;
        position: fixed;
        top: @headerHeight;
        left: 0px;
        right: 0px;
        height: 160px;
        flex-direction: row;
        padding-left: 240px; //和一级菜单对齐

        .menu-item {
            width: 160px;
            padding-top: 55px;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .active {
        background-color: #0000001A;
    }


}
</style>