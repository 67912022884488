<template>
    <div class="my-footer">
        <img src="@/assets/image/logo_text_gray.png" class="image">
        <div class="text">
            <span class="address">天津市南开区科研西路天津科技广场6号楼1606（天开园）</span>
            <span class="copyright">Copyright 2024 东泰医疗科技（天津）有限公司 版权所有</span>
            <span class="icp" @click="clickIcp">津ICP备2024013034号-1</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'my-footer',
    methods: {
        clickIcp() {
            window.open('https://beian.miit.gov.cn/')
        },
    },
}
</script>
<style lang="less" scoped>
.my-footer {
    width: 100%;
    height: 301px;
    background-color: #000080;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
        margin-top: 113px;
        width: 226px;
        height: 32px;
    }

    .text {
        margin-top: 64px;
        margin-bottom: 75px;
        display: flex;
        flex-direction: row;

        .address {
            font-weight: 600;
            font-size: 12px;
            color: #8F8F97;
        }

        .copyright {
            margin-left: 10px;
            font-weight: 600;
            font-size: 12px;
            color: #8F8F97;
        }

        .icp {
            margin-left: 5px;
            font-weight: 600;
            font-size: 12px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
</style>