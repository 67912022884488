<template>
  <div id="app">
    <RouteHost></RouteHost>
  </div>
</template>

<script>
import RouteHost from "@/views/RouteHost/RouteHost.vue"
export default {
  components: {
    RouteHost,
  },
}
</script>
<style lang="less">
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
